<template>
  <div>
    <div class="card-toolbar mb-5">
        <router-link  v-if="$can('receivable_cheques.create')" to="/cheques-cycle/receivable-cheques/create" class="btn btn-primary font-weight-bolder">
            <v-icon>mdi-plus</v-icon>{{ $t('receivable_cheques.add_receivable_cheques')}}
        </router-link>
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
            <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
        </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
        <div class="card-body">
            <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row mb-5">
                    
                    <div class="form-group col-md-3 mt-2 mb-2">
                        <label>{{$t('receivable_cheques.added_by')}}</label>
                        <!-- <select name="" id="added_by" v-model="filters.user_id" class="custom-select" >
                            <option v-for="row in users" :value="row.id" :key="row.id">
                                {{ row.name }}
                            </option>
                        </select> -->
                        <multiselect v-model="user"
                            :placeholder="$t('receivable_cheques.added_by')"
                            label="name"
                            track-by="id"
                            :options="users"
                            :multiple="false"
                            :taggable="false"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false"
                            @search-change="getUsers($event)">
                        </multiselect>
                    </div>

                    <div class="form-group col-md-3 mt-2 mb-2">
                        <label>{{$t('receivable_cheques.currency')}}</label>
                        <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select" >
                            <option v-for="row in currencies" :value="row.id" :key="row.id">
                                {{ row.name }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="form-group col-md-3 mt-2 mb-2">
                        <label for="from_amount">{{$t('receivable_cheques.from_amount')}}</label>
                        <input v-model="filters.from_amount" type="number" id="from_amount" class="form-control">
                    </div>

                    <div class="form-group col-md-3 mt-2 mb-2">
                        <label for="to_amount">{{$t('receivable_cheques.to_amount')}}</label>
                        <input v-model="filters.to_amount" type="number" id="to_amount" class="form-control">
                    </div>

                    <div class="form-group col-md-3 mt-2 mb-2">
                        <label for="from_issue_date">{{$t('receivable_cheques.from_issue_date')}}</label>
                        <input v-model="filters.from_issue_date" type="date" id="from_issue_date" class="form-control">
                    </div>
                    <div class="form-group col-md-3 mt-2 mb-2">
                        <label for="to_issue_date">{{$t('receivable_cheques.to_issue_date')}}</label>
                        <input v-model="filters.to_issue_date" type="date" id="to_issue_date" class="form-control">
                    </div>
                    <div class="form-group col-md-3 mt-2 mb-2">
                        <label for="from_due_date">{{$t('receivable_cheques.from_due_date')}}</label>
                        <input v-model="filters.from_due_date" type="date" id="from_due_date" class="form-control">
                    </div>
                    <div class="form-group col-md-3 mt-2 mb-2">
                        <label for="to_due_date">{{$t('receivable_cheques.to_due_date')}}</label>
                        <input v-model="filters.to_due_date" type="date" id="to_due_date" class="form-control">
                    </div>
                    
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="f_status">{{$t('status')}}</label>
                        <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                            <option value="">{{$t('all')}}</option>
                            <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="cheques_no">{{$t('receivable_cheques.cheques_no')}}</label>
                        <input v-model="filters.cheques_no" type="number" id="cheques_no" class="form-control">
                    </div>

                    
                    <div class="form-group d-inline-flex col-md-4 mt-6 mb-0">
                        <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                        </button>
                        <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!--begin::customer-->
    <div class="card card-custom">
      <div class="card-body">
          
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

            <template slot="status" slot-scope="props">

                <button v-if="$can('receivable_cheques.change_status')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
                    {{$t('change_status')}} ({{ props.row.status_name }})
                </button>

            </template>
            <template slot="actions" slot-scope="props">
              <v-btn   v-if="$can('receivable_cheques.update')" icon color="pink" :to="`/cheques-cycle/receivable-cheques/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2">mdi-pencil</v-icon>
              </v-btn>
                <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('receivable_cheques.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>

                <v-btn icon color="pink" :to="`/cheques-cycle/receivable-cheques/view/${props.row.id}`">
                    <v-icon small class="mr-2">mdi-eye</v-icon>
                </v-btn>
                <b-dropdown id="dropdown-offset" :text="$t('more_actions')" variant="outline-primary" class="m-2">
                    <router-link v-if="$can('receivable_cheques.send_mail')" class="dropdown-item" :to="`/settings/custom/email/${props.row.id}/ReceivableCheque`">
                        {{ $t('send_mail') }}
                    </router-link>
                </b-dropdown>
            </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
      <b-modal ref="status-modal" hide-footer :title="$t('change_status')">
          <change-status-form @hide-modal="hideModal()"
                              :status-list="status_list"
                              :url="routeChangeStatus"
                              :current-id="innerId"
                              :current-status="statusId"
                              @handling-data="getDataAfterChangeStatus">
          </change-status-form>
      </b-modal>
  </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService  from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

    export default {
        name: "index-cheques-receivable-cheques",
        components: {'change-status-form': ChangeStatusFrom,},
        data() {
            return {
                mainRoute:'chequescycle/receivable-cheques',
                routeChangeStatus : 'chequescycle/receivable-cheque/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    bank_id: null,
                    from_issue_date: null,
                    to_issue_date: null,
                    from_due_date: null,
                    to_due_date: null,
                    currency_id: null,
                    user_id: null,
                    status: null,
                    cheques_no: null,
                    from_amount: null,
                    to_amount: null,
                },
                status_list: [],
                columns: ['amount', 'cheques_no', 'currency_name',  'received_account', 'cheques_name', 'issue_date', 'due_date', 'status', 'actions'],
                data: [],
                currencies: [],
                users: [],
                user:null,
                innerId: null,
                statusId: null,
            }
        },
        watch:{
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        amount: that.$t('receivable_cheques.amount'),
                        cheques_no: that.$t('receivable_cheques.cheques_no'),
                        currency_name: that.$t('receivable_cheques.currency'),
                        received_account: that.$t('receivable_cheques.received_account'),
                        cheques_name: that.$t('receivable_cheques.cheques_name'),
                        issue_date: that.$t('receivable_cheques.issue_date'),
                        due_date: that.$t('receivable_cheques.due_date'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.receivable_cheques")}]);
            this.getCurrencies();
            this.getStatusList();
        },
        methods: {
            // changeStatus(id, status) {
            //     ApiService.patch(this.routeChangeStatus + '/' + id, {
            //         status: (status ? 1 : 0),
            //     }).then((response) => {
            //         this.$refs.table.refresh();
            //         this.$successAlert(response.data.message);
            //     }).catch(error => {
            //         this.$errorAlert(error);
            //     });
            // },
          openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.bank_id = null;
                this.filters.from_issue_date = null;
                this.filters.to_issue_date = null;
                this.filters.from_due_date = null;
                this.filters.to_due_date = null;
                this.filters.currency_id = null;
                this.filters.user_id = null;
                this.filters.status = null;
                this.filters.cheques_no = null;
                this.filters.from_amount = null;
                this.filters.to_amount = null;
                this.user= null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            getCurrencies() {
                ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
                    this.currencies = response.data.data;
                });
            },

            getUsers(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`${this.mainRouteDependency}/users`,{params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
                }else{
                    this.users = [];
                }
            },
            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/receivable_cheques").then((response) => {
                    this.status_list = response.data.data;
                });
            },
            actionDelete(item){
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            changeStatus(id, status) {
                this.innerId = id;
                this.statusId = status;
                this.showModal();
            },
            showModal() {
                this.$refs['status-modal'].show()
            },
            hideModal() {
                this.$refs['status-modal'].hide();
            },
            getDataAfterChangeStatus() {
                this.innerId = null;
                this.statusId = null;
                this.doFilter();
            },
        },
    };
</script>
